import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Axios from 'axios'

Vue.prototype.$http = Axios;
const token = localStorage.getItem('token');
if (token) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

// Add a request interceptor
Axios.interceptors.request.use(config => {
    // console.log(config)
    return config;
}, error => {
    return Promise.reject(error)
})

Axios.interceptors.response.use(response => {
    if (response.data == "Expired token") {
        router.push("/login")
    }
    return response
}, error => {
    return Promise.reject(error)
})




Vue.config.productionTip = false

// image croppa
import Croppa from 'vue-croppa'
import 'vue-croppa/dist/vue-croppa.css'
Vue.use(Croppa)

// Use Notify
import Notify from 'vue2-notify'
Vue.use(Notify, {
    duration: 300,
    visibility: 3000, 
    permanent: false,
    position: 'bottom-left',
    enter: 'fadeIn',
    leave: 'fadeOut',
    closeButtonClass: false,
})

// progress bar
import 'pace-progressbar'
import 'pace-progressbar/themes/black/pace-theme-flash.css'

// telephone input
import VueTelInput from 'vue-tel-input'
Vue.use(VueTelInput)


// country state
import vueCountryRegionSelect from 'vue-country-region-select'
Vue.use(vueCountryRegionSelect)


import birthDatepicker from 'vue-birth-datepicker'
import 'vue-birth-datepicker/dist/vueBirthDatepicker.css'
Vue.use(birthDatepicker)

// date time picker
import Datetime from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
Vue.use(Datetime)
 

import VueLoadImage from 'vue-load-image'
Vue.use(VueLoadImage)


import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
const swalOptions = {
    confirmButtonColor: '#41b882',
    cancelButtonColor: '#ff7674'
}
Vue.use(VueSweetalert2, swalOptions)
 
import IdleVue from 'idle-vue'
const eventsHub = new Vue()
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 10000
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
