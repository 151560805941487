import Vue from 'vue'
import Vuex from 'vuex'
import Axios from "axios"
import VuexPersist from 'vuex-persist'

const vuexLocalStorage = new VuexPersist({
    key: 'vuex-store-gcmg-v1.0.1',
    storage: window.localStorage,
})

Axios.defaults.baseURL = (location.hostname == "localhost" || location.hostname == "127.0.0.1") ? 'http://localhost/gcmgstaff/v1/api' : 'https://gcmgstaffapi.herokuapp.com/v1/api'
Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

Vue.use(Vuex)

export default new Vuex.Store({

    plugins: [ vuexLocalStorage.plugin, ],

    state: {
        
        token: localStorage.getItem('token') || "",

        user: {},
        
        staffAssignments: [
            'Student-Led Movement',
            'Global Church Movement',
            'LeaderImpact',
            'Digital Strategies',
            'Community Ministry',
            'Operations',
            'Leadership Development & Human Resource',
            'Prayer Movement',
            'Board of Directors',
            'National Team Leader',
            'Staff Assignment',
            'International Leadership Foundation',
            'Apher Institute',
            'Global Leadership',
            'Other',
        ],

        userId: null,
        
        loading: false,

        salutations: [
            'Apostle',
            'Arch Bishop',
            'Bishop',
            'Brother',
            'Elder',
            'Evangelist',
            'Deacon',
            'Deaconess',
            'Doctor',
            'Jewel',
            'Minister',
            'Mr',
            'Mrs',
            'Miss',
            'Ms',
            'Pastor',
            'Professor',
            'Prophet',
            'Prophetess',
            'Reverend',
            'Sister',
        ],

        gender: ['Male','Female'],

        maritalStatuses: [
            "Single",
            "Married",
            "Divorced",
            "Separated",
            "Widowed",
            "Dating",
            "Coarting",
        ],

        countryList: [
            {
                name: "Ghana",
                abbr: "GH",
            },
            {
                name: "Nigeria",
                abbr: "NG",
            }
        ],

        regionList: [
            "Ahafo Region",
            "Ashanti Region",
            "Bono East Region",
            "Brong Ahafo Region",
            "Central Region",
            "Eastern Region",
            "Greater Accra Region",
            "North East Region",
            "Northern Region",
            "Oti Region",
            "Savannah Region",
            "Upper East Region",
            "Upper West Region",
            "Volta Region",
            "Western North Region",
            "Western Region",
        ],

        currencies: ['GHS', 'USD', 'GBP', 'EUR', 'NGN', 'ZAR'],       

        roles: [
            'City Team Leader',
            'Regional Director ',
            'Cluster Director',
            'National MCC Leader',
            'Campus Director ',
            'Team Leader',
            'Finance Manager ',
            'Human Resource Director ',
            'Office Manager ',
            'Accountant',
            'Other',
        ],

        categories: [
            "Full Time Staff",
            "Associate Staff",
            "Volunteer Staff",
            "Employee Staff",
            "Intern",
        ],

        staffSearchResults: [],

        accessLevels: [
            {
                name: "Super Administrator",
                slug: "superadmin",
            },
            {
                name: "National Director",
                slug: "national-director",
            },
            {
                name: "Regional Director",
                slug: "reginal-director",
            },
            {
                name: "Human Resource Director",
                slug: "hr-director",
            },
            {
                name: "Temporary Account",
                slug: "temporary",
            },
        ],

        monthLabels: [
            'January', 
            'February', 
            'March', 
            'April', 
            'May', 
            'June', 
            'July', 
            'August', 
            'September', 
            'October', 
            'November', 
            'December'
        ],

    },

    mutations: {
        show_loading(state){
            state.loading = true
        },
        hide_loading(state){
            state.loading = false
        },
        user_logout(state){
            state.token = null 
            state.user = {}
            state.account = {}
            state.staffSearchResults = []
        },
        user_login(state, data){
            state.token = data.token 
            state.user = data.user
        },
        set_token(state, data){
            state.token = data.token 
        },
        account_registered(state){
            state.isInitRegistration = true
        },
        account_verified(state, data){
            state.token = data.token
            state.user = data.data.user
            state.account = data.data.account
            state.isInitRegistration = false
        },
        account_unverified(state){
            state.user = {}
            state.account = {}
            state.isInitRegistration = true
        },
        financial_search_results(state, data){
            state.financialSearchResults = data.members            
            state.financialSearchTotalResults = data.total
        },
        clear_financial_search(state){
            state.financialSearchResults = []
            state.financialSearchTotalResults = 0
        },
        concat_search(state, staff){
            state.staffSearchResults = staff
        },
        clear_search(state){
            state.staffSearchResults = []
        }
    },

    actions: {

        login({commit}, credentials){
            return new Promise((resolve, reject) => {
                Axios
                    .post('/accounts/login', credentials)
                    .then(response => {
                        if (response.data.code === 200) {
                            const token = response.data.token
                            localStorage.setItem('token', token)
                            Axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
                            commit('user_login', response.data)
                            resolve(response)
                        }
                    })
                    .catch(error => {
                        localStorage.removeItem('token')                
                        reject(error)
                    })
            })
        },

        logout({commit}){
            localStorage.removeItem('token')
            delete Axios.defaults.headers.common['Authorization']
            commit('user_logout')
        },

        register({commit}, accountInformation){
            return new Promise((resolve, reject) => {
                Axios.post('/accounts/register', accountInformation).then(response => {
                    if (response.data.code === 1000) {
                        commit('account_registered')
                    }
                    resolve(response)
                }, error => {
                    localStorage.removeItem('token')
                    reject(error)
                })
            })
        },

        verify({commit}, code){
            return new Promise((resolve, reject) => {
                Axios.post('/accounts/verify', code).then(response => {
                    if (response.data.code === 1004) {
                        commit('account_verified', response.data)
                        localStorage.setItem('token', response.data.token)
                        Axios.defaults.headers.common['Authorization'] = response.data.token
                    } else if (response.data.code === 1005) {
                        commit('account_unverified')                        
                    }
                    resolve(response)
                }, error => {
                    reject(error)
                })
            })
        },

        financialSearchMembers({commit}, keyword){
            return new Promise((resolve, reject) => {
                Axios.get('/financials/search', {
                    params: {
                        keyword: keyword
                    }
                }).then(response => {
                    commit('clear_financial_search')
                    commit('financial_search_results', response.data)
                    resolve(response)
                }, error => {
                    reject(error)
                })
            })
        },

        clearFinancialSearch({commit}){
            commit('clear_financial_search')
        },

        changePassword({commit}, credentials){
            return new Promise((resolve, reject) => {
                Axios.post('/accounts/changepassword', credentials).then(response => {
                    if (response.data.code === 200) {
                        const token = response.data.token
                        localStorage.setItem('token', token)
                        Axios.defaults.headers.common['Authorization'] = token
                        commit('set_token', token)
                    }
                    resolve(response)
                }, error => {
                    localStorage.removeItem('token')
                    reject(error)
                })
            })
        },

        loadStaff({commit}, keyword){
            return new Promise((resolve, reject) => {
                Axios
                    .get("/staff/search", {
                        params: {
                            keyword,
                            page: 1
                        }
                    })
                    .then(response => {
                        commit('concat_search', response.data.staff)
                        resolve(response)
                    }).catch(error => {
                        reject(error)
                    })
            })
        },

    },

    getters: {
        isLoggedIn: state => !!state.token,
        searchResults: state => state.staffSearchResults
    },

})
