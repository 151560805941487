import Vue from 'vue'
import Router from 'vue-router' 
import store from './store.js'

Vue.use(Router)

const router = new Router({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            component: () => import('@/views/Home.vue'),
            children: [
                {
                    path: '',
                    component: () => import('@/views/Dashboard/Dashboard.vue'),
                },
                {
                    path: 'add',
                    component: () => import('@/views/Staff/AddStaff.vue'),
                },
                {
                    path: 'staff',
                    component: () => import('@/views/Staff/AllStaff.vue'),
                },
                {
                    path: 'staff/:staffId',
                    component: () => import('@/views/Staff/ViewStaff.vue'),
                },
                {
                    path: 'staff/:staffId/edit',
                    component: () => import('@/views/Staff/EditStaff.vue'),
                },
                {
                    path: 'users',
                    component: () => import('@/views/Users/Index.vue'),
                    children: [
                        {
                            path: '',
                            component: () => import('@/views/Users/AllUsers.vue'),
                        },
                        {
                            path: 'add',
                            component: () => import('@/views/Users/AddUser.vue'),
                        },
                        {
                            path: 'user/:userId',
                            component: () => import('@/views/Users/AddUser.vue'),
                        },
                        {
                            path: 'user/:userId/edit',
                            component: () => import('@/views/Users/EditUser.vue'),
                        },
                    ]
                },
                {
                    path: 'adduser',
                    component: () => import('@/views/Users/AddUser.vue'),
                },
                {
                    path: 'profile',
                    component: () => import('@/views/Users/Profile.vue'),
                },
                {
                    path: 'settings',
                    component: () => import('@/views/Users/ChangePassword.vue'),
                },
                {
                    path: 'search',
                    component: () => import('@/views/Staff/Search.vue'),
                },
            ],
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/login',
            component: () => import('@/views/Auth/Login.vue'),
        },
        {
            path: '/register',
            component: () => import('@/views/Auth/Register.vue'),
        },
        {
            path: '/verify',
            component: () => import('@/views/Auth/Verify.vue'),
        },
        {
            path: '*',
            name: '404',
            component: () => import('./views/404.vue')
        }
    ]
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next()
            return
        }
        next('/login')
    } else {
        next()
    }
})

export default router